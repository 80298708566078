<template>
  <section class="c-cmp">
    <template v-if="loaded">
      <info-view
        v-if="infoView"
        :document="document"
        @toggle="toggleView"
        @update="updateTCString"
      />
      <consents-view
        v-else
        :data="data"
        :show-back-button="showBackButton"
        :show-vendors="showVendors"
        @back="goBack"
        @update="updateTCString"
        @vendors="updateShowVendors"
      />
    </template>
  </section>
</template>

<script>
  import { GVL, TCModel, TCString } from '@iabtcf/core'
  import InfoView from '@/components/popups/cmp/components/info-view'
  import ConsentsView from '@/components/popups/cmp/components/consents-view'
  import { uuid } from 'uuidv4'

  const CMP_COOKIE_NAME = 'eupubconsent-v2'

  export default {
    components: {
      ConsentsView,
      InfoView
    },
    data () {
      return {
        infoView: true,
        document: '',
        loaded: false,
        showVendors: false,
        purposesConsents: new Set(),
        purposesLITransparencys: new Set(),
        vendorConsents: new Set(),
        vendorLegitimateInterest: new Set(),
        specialFeatures: new Set(),
        vendors: {},
        purposes: {},
        consentList: {},
        features: {},
        specialPurposes: {},
        specialFeaturesPurposes: new Set(),
        showBackButton: false,
        specificationList: {},
        cmpUrlSpecifications: '',
        cmpUrlUpdateTc: '',
        cmpId: ''
      }
    },
    computed: {
      data () {
        return {
          consentList: this.consentList,
          vendors: this.vendors,
          purposes: this.purposes,
          features: this.features,
          specialPurposes: this.specialPurposes,
          specialFeatures: this.specialFeatures,
          purposesConsents: this.purposesConsents,
          purposesLITransparencys: this.purposesLITransparencys,
          vendorConsents: this.vendorConsents,
          vendorLegitimateInterest: this.vendorLegitimateInterest,
          specialFeaturesPurposes: this.specialFeaturesPurposes
        }
      },
      TCString () {
        return this.$store.state.cmp.TCString
      }
    },
    beforeDestroy () {
      document.querySelector('#cmp-purposes')
        ?.removeEventListener('click', this.redirectToPurposes)
      document.querySelector('#cmp-vendors')
        ?.removeEventListener('click', this.redirectToVendors)
    },
    mounted () {
      this.loadData()
    },
    methods: {
      async loadData () {
        this.$loader.show(this.$el.parentElement)
        if (this.TCString) {
          this.infoView = false
        }
        await this.$store.dispatch('cmp/GET_CMP_INFO')
          .then((res) => {
            this.cmpUrlSpecifications = res.specification_url
            this.cmpUrlUpdateTc = res.tc_url
            this.cmpId = res.cmp_id
          })

        await this.$store.cache.dispatch('cmp/GET_CONSENT_LIST', this.cmpUrlSpecifications)
          .then(res => {
            this.document = res.notice
            this.vendors = res.vendors
            this.purposes = res.purposes
            this.consentList = res.noticeShort
            this.features = res.features
            this.specialPurposes = res.specialPurposes
            this.specialFeatures = res.specialFeatures
            this.specificationList = res

            if (!this.TCString) {
              const defaultTCString = this.consentList?.defaultTCString
              this.$store.dispatch('cmp/SET_DEVICE_UID', uuid())

              if (defaultTCString) {
                this.$cmp.update(defaultTCString)
                this.$cookie.set(CMP_COOKIE_NAME, defaultTCString)
              }
            }
          })
          .finally(() => {
            this.populateData()
            this.$loader.hide(this.$el.parentElement)
            this.loaded = true

            this.$nextTick(() => {
              this.linkClickedListener('#cmp-purposes', this.redirectToPurposes)
              this.linkClickedListener('#cmp-vendors', this.redirectToVendors)
            })
          })
      },
      linkClickedListener (id, redirect) {
        return document.querySelector(`${id}`)
         ?.addEventListener('click', redirect)
      },
      populateData () {
        if (this.TCString) {
          const decoded = TCString.decode(this.TCString)
          this.purposesConsents = decoded.purposeConsents.set_
          this.purposesLITransparencys = decoded.purposeLegitimateInterests.set_
          this.vendorConsents = decoded.vendorConsents.set_
          this.vendorLegitimateInterest = decoded.vendorLegitimateInterests.set_
          this.specialFeaturesPurposes = decoded.specialFeatureOptins.set_
        }
      },
      toggleView () {
        this.infoView = !this.infoView
      },
      updateTCString (opts = {}) {
        this.$loader.show(this.$el.parentElement)

        if (opts.acceptAll) {
          this.acceptAll()
        }

        if (opts.rejectAll) {
          this.rejectAll()
        }

        if (opts.saveButtonDisabled) {
          this.TCString ? this.$emit('close') : this.goBack()
          this.$loader.hide(this.$el.parentElement)
          return
        }

        const newModel = {
          deviceUid: this.$store.state.cmp.deviceUID,
          purposesConsents: Array.from(this.purposesConsents),
          purposesLITransparencys: Array.from(this.purposesLITransparencys),
          specialFeatureOptIns: Array.from(this.specialFeaturesPurposes),
          vendorConsents: Array.from(this.vendorConsents),
          vendorLegitimateInterest: Array.from(this.vendorLegitimateInterest)
        }

        this.$store.dispatch('cmp/UPDATE_TC_STRING', { payload: newModel, cmpUrl: this.cmpUrlUpdateTc })
          .then(() => {
            this.saveConsents(newModel)
            this.$toast.success(this.$t('cmp.TCStringUpdated'))
            this.$emit('close')
          })
          .catch(() => this.$toast.error(this.$t('cmp.TCStringUpdatingError')))
          .finally(() => this.$loader.hide(this.$el.parentElement))
      },
      acceptAll () {
        this.vendorLegitimateInterest = new Set()
        this.vendorConsents = new Set()
        this.purposesConsents = new Set()
        this.purposesLITransparencys = new Set()
        this.specialFeaturesPurposes = new Set()
        this.copyPurposesFromGVL({ withConsents: true })
        this.copyVendorsFromGVL({ withConsents: true })
        this.copySpecialFeaturesFromGVL({ withConsents: true })
      },
      rejectAll () {
        this.vendorConsents = new Set()
        this.purposesConsents = new Set()
        this.specialFeaturesPurposes = new Set()
      },
      copyPurposesFromGVL (opts = {}) {
        return Object.entries(this.purposes).map(item => {
          const purpose = item?.[1]
          if (opts.withConsents) {
            this.purposesConsents.add(purpose.id)
          }
          this.purposesLITransparencys.add(purpose.id)
        })
      },
      copyVendorsFromGVL (opts = {}) {
        return Object.entries(this.vendors).map(item => {
          const vendor = item?.[1]

          if (opts.withConsents) {
            this.vendorConsents.add(vendor.id)
          }

          if (vendor?.legIntPurposes?.length) {
            this.vendorLegitimateInterest.add(vendor.id)
          }
        })
      },
      copySpecialFeaturesFromGVL (opts = {}) {
        Object.entries(this.specialFeatures).map(item => {
          const specialFeature = item?.[1]

          if (specialFeature) {
            this.specialFeaturesPurposes.add(specialFeature.id)
          }
        })
      },
      redirectToVendors (e) {
        e.preventDefault()
        this.showVendors = true
        this.infoView = false
        this.showBackButton = true
      },
      redirectToPurposes (e) {
        e.preventDefault()
        this.showVendors = false
        this.infoView = false
        this.showBackButton = true
      },
      goBack () {
        this.showVendors = false
        this.infoView = true
        this.showBackButton = false
        this.$nextTick(() => {
          this.linkClickedListener('#cmp-purposes', this.redirectToPurposes)
          this.linkClickedListener('#cmp-vendors', this.redirectToVendors)
        })
      },
      updateShowVendors (showVendors) {
        this.showVendors = showVendors
      },
      updateData ({ id, isChecked }, attr) {
        if (isChecked) {
          return this[attr].add(id)
        }

        return this[attr].delete(id)
      },
      // @TODO check if this can be replaced with refs
      clickParent (opts = {}) {
        const switchElement = document.querySelector(`#${opts.element}-consent-${opts.id} .c-switch .switch`)
        if (switchElement && switchElement?.children?.[0]?.checked) {
          switchElement.click()
        }
      },
      saveConsents (newModel) {
        const tcModel = new TCModel(new GVL(this.specificationList))

        tcModel.purposeConsents.set(newModel.purposesConsents)
        tcModel.purposeLegitimateInterests.set(newModel.purposesLITransparencys)
        tcModel.specialFeatureOptins.set(newModel.specialFeatureOptIns)
        tcModel.vendorConsents.set(newModel.vendorConsents)
        tcModel.vendorLegitimateInterests.set(newModel.vendorLegitimateInterest)
        tcModel.cmpId = this.cmpId
        tcModel.isServiceSpecific = true
        tcModel.publisherCountryCode = 'PL'

        const tcStringEncoded = TCString.encode(tcModel)
        this.$store.state.cmp.TCString = tcStringEncoded
        this.$cmp.update(tcStringEncoded)
        this.$cookie.set(CMP_COOKIE_NAME, tcStringEncoded)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
